.footer p {
	font-size: 12px;
}
.breadcrumb {
	background-color: #f3f6fa;
}
.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
	color: #007bff;
}
.home-cover {
	background:#ffffff url('/images/homepage/boat_sunset_br.jpg') no-repeat top center /cover;
}
.navbar-dark .nav-item a {
	color:white;
}
ol,ul {
	display: grid;
}
::placeholder {
  color: #99a6b4 !important;
  opacity: 1;
}
:-ms-input-placeholder {
  color: #99a6b4;
}
::-ms-input-placeholder {
  color: #99a6b4;
}
.clearp {
	clear:both;
	padding-top:10px;
}
.main-article {
	padding: 0.5rem;
}
.main-article *:not(img,a){
	padding-left: 1rem;
	padding-right: 1rem;
}
.imgrp {
	margin:0;
	margin-top: -.75rem;
	padding:1rem;
}
.imgrp.rounded{
	border-radius: 1.25rem !important;
}
.imgrp.float-left {

}
.imgrp.float-right {

}
.imgrp.float-center {
	margin-left: auto;
    margin-right: auto;
	display: block;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.card a {
    color: black;
}
.card-deck .card {
    min-width: 220px;
}
.card-5 {
	height: 5rem;
    object-fit: cover;
}
.card-14 {
	height: 14rem;
    object-fit: cover;
}
.expand-on-hover {
	transition-duration:.2s;
}
.pointer {
	cursor: pointer;
}
.expand-on-hover:hover{
	transition-duration:.1s;
	transition-timing-function:ease-in-out;
	transition-delay:initial;
	transition-property:initial;
	transform:scale(0.984);
	/*box-shadow:rgba(45,62,80,.15) 0 2px 5px 0px*/
}
.card a:hover {
    color: #007bff;
    text-decoration: none;
}
.article-thumb {
	height: 225px;
    object-fit: cover;
	width: 100%;
    object-position: center;
    display: block;
}
.footer-logo {
	text-align: center;
}
.card-img-caption {
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
}

.card-img-caption .card-img-top {
  z-index: 0;
}

.card-img-caption .card-text {
	font-size: 2rem;
    line-height: 5rem;
    color: #fff;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    width: 100%;
    position: absolute;
    z-index: 1;
}
.cover-title h1 {
	font-size: 2rem;
	font-weight: 600;
	text-shadow: 2px 2px 2px rgba(0,0,0,0.13);
}
.cover-title p {
	font-size:1.1rem;
	font-weight: 700;
	text-shadow: 2px 2px 2px rgba(0,0,0,0.13);
}
.captain-img {
	object-fit: cover;
	width:100%;
	height:355px;
}
.service-icon {
	font-size:50px;
}
.clr-1-bg {
	background-color: #43B99F;
}
.clr-2-bg {
	background-color: #7599c5;
}
.clr-3-bg {
	background-color: #f1bc5f;
}
.clr-1 {
	color: #43B99F;
}
.clr-2 {
	color: #7599c5;
}
.clr-3 {
	color: #f1bc5f;
}

@media (min-width: 576px) {
	.cover-title h1 {
		font-size: 2.5rem;
	}
	.cover-title p {
		font-size:1.3rem;
	}
	.articles .card-title {
		width: 18rem;
	}
    .card-columns {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
    }
}
@media (min-width: 768px) {
	.captain-img {
		width:255px;
		height:255px;
		margin-right: 1rem;
	}
	.cover-title h1 {
		font-size: 3rem;
	}
	.cover-title p {
		font-size:1.4rem;
	}
    .card-columns {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
    }

	.footer-logo {
		text-align: left;
	}
}
@media (min-width: 992px) {
	.cover-title h1 {
		font-size: 4rem;
	}
	.cover-title p {
		font-size:1.5rem;
	}
    .card-columns {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
    }
	.card-img-caption .card-text {
	    font-size: 1.5rem;
	}
}
@media (min-width: 1200px){
	.cover-title h1 {
		font-size: 4rem;
	}
    .card-columns {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
    }
}
.grecaptcha-badge {
    visibility: hidden;
}
.recaptcha-legal {
	color: gray;
	font-size: 10px;
	line-height: 12px;
	height: 7px;
}
.exp .checkbox {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  text-align: center;
}

.exp label {
  display: inline-block;
  color: #007bff;
  cursor: pointer;
  position: relative;
}
.exp label span {
  display: inline-block;
  position: relative;
  background-color: transparent;
  width: 25px;
  height: 25px;
  transform-origin: center;
  border: 2px solid #007bff;
  border-radius: 50%;
  vertical-align: -6px;
  margin-right: 10px;
  transition: background-color 150ms 200ms, transform 350ms cubic-bezier(0.78, -1.22, 0.17, 1.89);
}
.exp label span:before {
  content: "";
  width: 0px;
  height: 2px;
  border-radius: 2px;
  background: #007bff;
  position: absolute;
  transform: rotate(45deg);
  top: 11px;
  left: 7px;
  transition: width 50ms ease 50ms;
  transform-origin: 0% 0%;
}
.exp label span:after {
  content: "";
  width: 0;
  height: 2px;
  border-radius: 2px;
  background: #007bff;
  position: absolute;
  transform: rotate(305deg);
  top: 14px;
  left: 8px;
  transition: width 50ms ease;
  transform-origin: 0% 0%;
}
.exp label:hover span:before {
  width: 5px;
  transition: width 100ms ease;
}
.exp label:hover span:after {
  width: 10px;
  transition: width 150ms ease 100ms;
}

input[type="checkbox"] {
  display: none;
}
input[type="checkbox"]:checked + label span {
  background-color: #007bff;
  transform: scale(1.25);
}
input[type="checkbox"]:checked + label span:after {
  width: 10px;
  background: #fff;
  transition: width 150ms ease 100ms;
}
input[type="checkbox"]:checked + label span:before {
  width: 5px;
  background: #fff;
  transition: width 150ms ease 100ms;
}
input[type="checkbox"]:checked + label:hover span {
  background-color: #007bff;
  transform: scale(1.25);
}
input[type="checkbox"]:checked + label:hover span:after {
  width: 10px;
  background: #fff;
  transition: width 150ms ease 100ms;
}
input[type="checkbox"]:checked + label:hover span:before {
  width: 5px;
  background: #fff;
  transition: width 150ms ease 100ms;
}


/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}
.marketing h2 {
  font-weight: 400;
}
.marketing .col-lg-4 p {
  margin-right: .75rem;
  margin-left: .75rem;
}


/* Featurettes
------------------------- */

.featurette-divider {
  margin: 5rem 0; /* Space out the Bootstrap <hr> more */
  border-top: 0;
}

/* Thin out the marketing headings */
.featurette-heading {
  font-weight: 300;
  line-height: 1;
  letter-spacing: -.05rem;
}

.featurette-heading-rev {
  margin-top: 0.7rem;
}


/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 40em) {
  .featurette-heading {
    font-size: 50px;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 3rem;
  }
  .featurette-heading-rev {
    margin-top: 0.7rem;
  }
}
